<template>
  <div>
    <!-- Breadcrumb -->
    <b-row
      class="content-header"
    >
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Alerts
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/apps/alerts/list/">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  to="/apps/alerts/list/"
                >
                  Alerts
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  active
                >
                  Manage
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-card v-if="itemData && ($can('create', abilityRequired) || $can('update', abilityRequired))">
      <b-card-header>
        <h5>
          Alert Details
        </h5>
      </b-card-header>
      <b-card-body>
        <!-- Input Fields -->
        <validation-observer
          ref="observer"
          #default="{ handleSubmit }"
        >
          <b-form
            @submit.prevent="handleSubmit(validateForm)"
          >
            <!-- Field: detail -->
            <b-row>
              <b-col
                md="12"
              >
                <b-form-group
                  label="Detail "
                  label-for="detail"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="detail"
                    rules="required"
                  >
                    <b-form-textarea
                      id="action_taken"
                      v-model="itemData.detail"
                      rows="3"
                      max-rows="8"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: is_shown -->
            <b-row>
              <b-col
                md="2"
              >
                <b-form-group
                  label=""
                  label-for="is_shown"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="is_shown"
                  >
                    <b-form-checkbox
                      id="is_shown"
                      v-model="itemData.is_shown"
                      checked="true"
                      unchecked-value="false"
                      name="check-button"
                      switch
                      inline
                      class="mt-2"
                    >
                      Show
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Submit Button -->
            <div
              v-if="itemSK === null"
            >
              <b-row class="mt-3">
                <b-col
                  v-if="$can('create', abilityRequired)"
                  md="3"
                >
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    type="submit"
                    :disabled="preventDoubleClick"
                  >
                    Add
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <!-- Buttons -->
            <div
              v-if="itemSK !== null"
            >
              <b-row class="mt-3">
                <b-col
                  v-if="$can('update', abilityRequired)"
                  md="2"
                >
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    type="submit"
                    :disabled="preventDoubleClick"
                  >
                    Save Changes
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <div>
              <b-row class="mt-3">
                <b-col
                  v-if="$can('delete', abilityRequired)"
                  md="2"
                >
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    @click="deleteItem()"
                  >
                    Delete Alert
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { Storage, Auth } from 'aws-amplify'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
import { required } from '@validations'
import {
  BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BFormGroup, BFormTextarea, BForm, BFormCheckbox, BBreadcrumb, BBreadcrumbItem,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import storeModule from '../storeModule'

// Notification
// import { useToast } from 'vue-toastification/composition'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// import ImageUploader from 'vue-image-upload-resize'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BFormTextarea,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      preventDoubleClick: false,
      itemData: {},
    }
  },
  setup() {
    const abilityRequired = 'alerts'
    const storeModuleName = 'app-alerts'

    // Determine if we are creating or editing an item
    let itemSK = null
    if (router.currentRoute.params.SK !== undefined && router.currentRoute.params.SK !== null) {
      // Get the SK for the item - used to get the full details of the item
      itemSK = router.currentRoute.params.SK
    }
    // Register module
    if (!store.hasModule(storeModuleName)) store.registerModule(storeModuleName, storeModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(storeModuleName)) store.unregisterModule(storeModuleName)
    })

    return {
      abilityRequired,
      storeModuleName,
      itemSK,
    }
  },
  created() {
    // Get the item details if SK is passed in
    if (this.itemSK === null) {
      this.itemData.SK = null
      this.itemData.detail = ''
      this.itemData.is_shown = true
    } else {
      this.getExistingItemData()
    }
  },
  methods: {
    getExistingItemData() {
      console.log('getExistingItemData')
      if (this.itemSK !== null && ability.can('update', this.abilityRequired)) {
        console.log('getting item data')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Getting Data',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        const qvars = {
          abilityRequired: this.abilityRequired,
          SK: this.itemSK,
        }
        store.dispatch(`${this.storeModuleName}/fetchItem`, qvars)
          .then(response => {
            console.log(response)
            this.itemData = response.data
          })
          .catch(error => {
            console.log(error)
            let theErrorStr = 'The following error(s) where received:\n'
            if (typeof error.response.data.errors !== 'undefined') {
              error.response.data.errors.forEach(item => {
                theErrorStr += `${item.message}\n`
              })
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error Getting Alert.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: theErrorStr,
              },
            })
          })
      }
    },
    validateForm() {
      console.log('validateForm')
      let proceedWithSave = false
      let dispatchLocation = ''
      let toastText = ''
      let processType = null
      if (this.itemSK === null && ability.can('create', this.abilityRequired)) {
        console.log('adding alert')
        processType = 'add'
        proceedWithSave = true
        dispatchLocation = `${this.storeModuleName}/addItem`
        toastText = 'Add'
      } else if (this.itemSK !== null && ability.can('update', this.abilityRequired)) {
        console.log('editing alert')
        processType = 'update'
        proceedWithSave = true
        dispatchLocation = `${this.storeModuleName}/updateItem`
        toastText = 'Updat'
      }
      // Additional Manual Validation

      if (proceedWithSave) {
        console.log(this.itemData)
        console.log(JSON.parse(JSON.stringify(this.itemData)))
        if (proceedWithSave) {
          this.$refs.observer.validate().then(success => {
            if (success) {
              this.preventDoubleClick = true
              console.log('form validated')
              console.log(this.itemData)
              // Create a clean dataset with only the data being updated
              const cleanData = JSON.parse(JSON.stringify(this.itemData))
              // delete cleanData.abilities
              console.log(cleanData)
              // Call backend
              store.dispatch(`${dispatchLocation}`, cleanData)
                .then(response => {
                  this.preventDoubleClick = false
                  console.log(response)
                  if (processType === 'add') {
                    this.itemSK = response.SK
                    this.itemData.SK = response.SK
                  }
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Alert ${toastText}ed`,
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                })
                .catch(error => {
                  console.log(error)
                  this.preventDoubleClick = false
                  let theErrorStr = 'The following error(s) where received:\n'
                  if (typeof error.response.data.errors !== 'undefined') {
                    error.response.data.errors.forEach(item => {
                      theErrorStr += `${item.message}\n`
                    })
                  }
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `Error ${toastText}ing Alert.`,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: theErrorStr,
                    },
                  })
                })
            }
          })
        }
      }
    },
    deleteItem() {
      console.log('deleteItem')
      if (this.$ability.can('delete', 'clients')) {
        this.$bvModal
          .msgBoxConfirm('Please confirm that you want to delete the Alert.', {
            title: 'Delete Alert',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              store
                .dispatch(`${this.storeModuleName}/deleteItem`, this.itemData)
                .then(response => {
                  console.log(response)
                  // Route client
                  const nextRout = { name: 'apps-alerts' }
                  this.$router.replace(nextRout)
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Alert deleted successfully',
                          icon: 'CheckIcon',
                          variant: 'success',
                        },
                      })
                    })
                })
                .catch(error => {
                  console.log(error)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `Error deleting Alert. ${error}`,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })
            }
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
#fileInput {
  display: none;
}
.radio-spacing .custom-control {
    margin-top: 10px; /* Adjust this value to increase or decrease spacing */
}
</style>
